<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  event: {
    type: Object,
    default: null
  }
})

const emit = defineEmits(['update:modelValue'])

const { $dayjs } = useNuxtApp()

const dialog = computed({
  get: () => !!props.modelValue,
  set: val => emit('update:modelValue', val),
})

const formattedDescription = computed(() => {
  // Replace image URLs in square brackets with <img> tags
  return (props.event?.description || 'No Description Provided').replace(
    /\[([^\]]+)\]/g,
    '<img src="$1" alt="inline image" style="width:100%;height:auto;" />'
  );
});
</script>

<template>
  <v-dialog
    v-model="dialog"
    width="auto"
  >
    <v-card width="500">
      <v-toolbar density="compact">
        <v-toolbar-title>
          Event Details
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          density="comfortable"
          icon="mdi-close"
          variant="text"
          @click="dialog = false"
        />
      </v-toolbar>
      <v-list>
        <v-list-item prepend-icon="mdi-calendar">
          <v-list-item-title>
            {{ props.event?.title }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ $dayjs(props.event?.startDate).format('MMMM DD, YYYY h:mm A') }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item prepend-icon="mdi-map-marker">
          {{ props.event?.location || 'No Location Provided' }}
        </v-list-item>
        <v-list-item prepend-icon="mdi-text" >
          <div v-html="formattedDescription"></div>
        </v-list-item>
      </v-list>
    </v-card>
  </v-dialog>
</template>
